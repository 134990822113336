<template>
    <div>
        <b-card>
            <div style="font-weight: bold; font-size: 20px;">Začetna stran trgovine</div>
            <div class="mt-2">
                Aktualna kategorija
            </div>
            <div class="mt-1">
                <Select :disabled="!$hasPermissions($permissions.EditWebShopConfiguration)" v-model="currentCategory" :options="optionsAllCategories" label="title" reduceField="id" text="Aktualna Kategorija"></Select>
            </div>

            <div class="mt-2">
                Top 3 kategorije
            </div>
            <div class="mt-1">
                <Select :disabled="!$hasPermissions($permissions.EditWebShopConfiguration)" v-model="topCategories" :optionsMultiple="optionsAllCategories" label="title" :selectableMultiple="() => topCategories.length < 3" reduceField="id" text="Aktualna Kategorija" type="multiple"></Select>
            </div>
            <div class="mt-1">
                <b-form-group label="Število izdelkov na stran">
                    <b-form-input :disabled="!$hasPermissions($permissions.EditWebShopConfiguration)" type="number" :state="isGTZ" v-model="numberOfArticles"></b-form-input>
                </b-form-group>
                <b-form-invalid-feedback id="input-live-feedback">
                    Število izdelkov na stran more biti večje od 0
                </b-form-invalid-feedback>
            </div>

            <b-button class="mt-2" @click="updateConfiguration" :disabled="!$hasPermissions($permissions.EditWebShopConfiguration)">Posodobi konfiguracijo</b-button>
        </b-card>
    </div>
</template>

<script>
    import { BCard, BButton, BFormInput } from 'bootstrap-vue'
    import Select from '../../Components/Select'

    export default {
        components:{
            BCard,
            Select,
            BButton,
            BFormInput
        },
        data() {
            return {
                currentCategory: null,
                optionsAllCategories: [],
                numberOfArticles:'',
                topCategories: []
            }
        },
        methods:{
            async getCurrentConfiguration() {
                try {
                    const res = await this.$http.get('/api/management/v1/webshop/configuration/homepage')

                    if (res.data) {
                        this.currentCategory = res.data.current_category
                        this.topCategories = res.data.top_categories
                        this.numberOfArticles = res.data.number_of_articles_per_page
                    }

                } catch (err) {
                    this.$printError('Pri pridobivanju konfiguracije je prislo do napake')
                }
            },
            async updateConfiguration() {
                try {
                    if (!this.isGTZ) {
                        return
                    }

                    const payload = {
                        current_category: this.currentCategory,
                        top_categories: this.topCategories,
                        number_of_articles_per_page:Number(this.numberOfArticles)
                    }

                    await this.$http.post('/api/management/v1/webshop/configuration/homepage', payload)

                    this.$printSuccess('Konfiguracija uspesno posodobljena')

                } catch (err) {
                    this.$printError('Pri posodobljanju konfiguracije je prislo do napake')
                }
            },
            async getAllCategories() {
                try {
                    this.optionsAllCategories = []

                    const res = await this.$http.get('/api/management/v1/shop/category')

                    for (const main_category of res.data) {
                        this.optionsAllCategories.push({id: main_category.id, title: main_category.title})

                        if (main_category.children) {
                            for (const child_category of main_category.children) {
                                const title = `${main_category.title  } -> ${  child_category.title}`
                                this.optionsAllCategories.push({id: child_category.id, title })
                            }
                        }

                    }

                } catch (err) {
                    this.$printError('Pri nalaganju je prislo do napake')
                }

            }
        },
        computed:{
            isGTZ() {
                return this.numberOfArticles > 0
            }
        },
        mounted() {
            this.getAllCategories()
            this.getCurrentConfiguration()
        }
    
    }
</script>